import React from 'react'
import classNames from 'classnames'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faShare } from '@fortawesome/pro-light-svg-icons/faShare'
import { Button, Logo, Markdown, Notification } from 'components/sparkles'
import AddressModalController from '../AddressModalController/AddressModalController'
import AuthModal from '../AuthModal/AuthModal'
import BallotLink from '../BallotLink/BallotLink'
import LeadBar from '../LeadBar/LeadBar'
import localNavContainer from './localNavContainer'
import ElectionCenterMobileNavButton from '../election_center/ElectionCenterNavigation/ElectionCenterMobileNavButton/ElectionCenterMobileNavButton'
import SignOutButton from '../SignOutButton/SignOutButton'
import SearchBar from 'components/search/SearchBar/SeachBar'
import { Ballot } from 'types'
import useAnalytics from 'hooks/useAnalytics'
import getPathForApp from 'utils/getPathForApp'
import styles from './LocalNav.module.scss'

export interface Props extends RouteComponentProps {
  addressUpdateNeedsBallot?: boolean
  alert?: string | null
  auth?: any // TODO type
  authEnabled?: boolean
  backButton?: boolean
  ballot?: Ballot
  center?: boolean
  className?: string
  customButton?: {
    title: string
    url: string
  }
  enableMaptv?: boolean
  enableSearch?: boolean
  enableVoterRegistration?: boolean
  onAlertClose?: () => void
  onLeadBarClose: () => void
  onShareableBallotBannerClose: () => void
  onUpdateAddress?: () => void
  homePath?: string
  isSmallScreen?: boolean
  lead?: any // TODO Type
  logoLinkUrl?: string
  logoUrl?: string
  showAlert?: boolean
  showECNavigation?: boolean
  showLeadBar?: boolean
  showShareableBallotBanner?: boolean
  sticky?: boolean
  tenantName: string
}

const LocalNav = ({
  addressUpdateNeedsBallot = true,
  alert,
  auth,
  authEnabled = false,
  backButton = false,
  ballot,
  center = false,
  className,
  customButton,
  enableMaptv = false,
  enableSearch = false,
  enableVoterRegistration = false,
  onAlertClose,
  onLeadBarClose,
  onShareableBallotBannerClose,
  onUpdateAddress,
  history,
  homePath = '/',
  lead,
  location,
  logoLinkUrl,
  logoUrl,
  showAlert = false,
  showECNavigation,
  showLeadBar = false,
  showShareableBallotBanner = false,
  sticky = false,
  tenantName,
}: Props) => {
  const { i18n, t } = useTranslation()
  const analytics = useAnalytics()

  const onClickMaptv = () => {
    analytics?.track('Nav bar: Clicked how to vote')
  }

  const onViewBallotClick = () => {
    analytics?.track('Nav bar: Clicked view ballot')
  }

  const rootPath = getPathForApp('voter_guide')
  const globalAlertKey = `alerts:GlobalAlert`
  const globalAlert =
    i18n.exists && i18n.exists(`${globalAlertKey}`) && t(`${globalAlertKey}`)

  const showSearch =
    enableSearch && history.location.pathname.includes('search')

  return (
    <header className={classNames(styles.LocalNav__container, className)}>
      {showAlert && (
        <Notification
          className={styles.LocalNav__GlobalAlert}
          dismissable
          onClose={onAlertClose}
          variant="warning"
        >
          <Markdown source={alert || globalAlert} />
        </Notification>
      )}

      {showShareableBallotBanner && (
        <Notification
          className={styles.LocalNav__ShareableBallotBanner}
          dismissable
          onClose={onShareableBallotBannerClose}
          variant="success"
          withIcon={false}
        >
          {t('LocalNav.shareMotivation')}
          <Button
            icon={faShare}
            outline
            to={`${rootPath}share`}
            variant="white"
          >
            {t('LocalNav.share')}
          </Button>
        </Notification>
      )}

      {showLeadBar && <LeadBar lead={lead} onClose={onLeadBarClose} />}

      <nav
        className={classNames(
          styles.LocalNav,
          center && styles.LocalNav__center,
          sticky && styles.LocalNav__sticky,
        )}
      >
        <div className={styles.LocalNav__Left}>
          {backButton && location.pathname !== homePath && (
            <Link
              className={styles.LocalNav__Back}
              to={homePath}
              title={t('LocalNav.back')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
          )}

          <div className={styles.LocalNav__BrandContainer}>
            {showECNavigation && <ElectionCenterMobileNavButton />}

            {logoLinkUrl ? (
              <a
                className={styles.LocalNav__Brand}
                href={logoLinkUrl}
                title={t('LocalNav.home')}
              >
                <Logo src={logoUrl} alt={tenantName} />
              </a>
            ) : (
              <BallotLink
                className={styles.LocalNav__Brand}
                to={homePath}
                title={t('LocalNav.home')}
              >
                <Logo src={logoUrl} alt={tenantName} />
              </BallotLink>
            )}
          </div>
          {showSearch && <SearchBar isInNav />}
        </div>

        <div className={styles.LocalNav__Inner}>
          <div className={styles.LocalNav__FeatureButtons}>
            <span className={styles.address}>{ballot?.address}</span>

            <AddressModalController
              shouldRedirect={false}
              onUpdateAddress={onUpdateAddress}
              needsBallot={addressUpdateNeedsBallot}
              modalTitle={t('LocalNav.updateAddress')}
              openModalClickStreamEvent="From Nav Bar"
            >
              {({ onClick }) => (
                <Button
                  className={styles.LocalNav__BtnAddress}
                  onClick={onClick}
                  outline
                  data-cy="update_address_button"
                  variant="default"
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    title={t('LocalNav.updateAddress')}
                  />
                </Button>
              )}
            </AddressModalController>

            {history.location.pathname.includes('/v') && (
              <Button
                className={styles.LocalNav__ViewBallotButton}
                size="sm"
                onClick={onViewBallotClick}
                to={`${getPathForApp('voter_guide')}review`}
                variant="primary"
              >
                {t('LocalNav.viewBallot')}
              </Button>
            )}
          </div>

          {customButton && customButton.url && (
            <Button href={customButton.url} outline size="sm" variant="default">
              {customButton.title}
            </Button>
          )}

          {authEnabled && !auth.isSignedIn && (
            // @ts-ignore
            <AuthModal signUpEnabled={!!ballot} />
          )}
          {authEnabled && auth.isSignedIn && <SignOutButton />}
        </div>
      </nav>
    </header>
  )
}

export default localNavContainer(withRouter(LocalNav))
