import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmailIcon } from 'assets/images/email-crooked.svg'
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-crooked.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-crooked.svg'
import PrintIcon from 'assets/images/icon-print.svg'
import styles from './SocialButtons.module.scss'
import useAnalytics from 'hooks/useAnalytics'

interface Props {
  emailSubject?: string
  includePrint?: boolean
  shareText: string
  sourcePage: string
  website: string
}

const SocialButtons: React.FC<Props> = ({
  emailSubject,
  includePrint = false,
  shareText,
  sourcePage,
  website,
}: Props) => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const logAction = (socialType: string) => {
    const values = {
      socialType,
      sourcePage,
    }
    analytics?.track('Shared on social media', values)
  }

  const onPrint = () => {
    window.print()
    logAction('print')
  }

  const onClickSocial = (socialType: string, url: string) => {
    window.open(url, '_blank')
    logAction(socialType)
    return undefined
  }

  return (
    <>
      {includePrint && !!window && (
        <button
          onClick={onPrint}
          tabIndex={0}
          title="Print"
          className={styles.printButton}
        >
          <img alt="Print" className="SocialBanner__icon" src={PrintIcon} />
        </button>
      )}
      <button
        className={styles.printButton}
        title={t('maptv:SummaryScene.SocialBanner.shareTwitter')}
        onClick={() =>
          onClickSocial(
            'twitter',
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              shareText,
            )}`,
          )
        }
      >
        <TwitterIcon />
      </button>

      <button
        className={styles.printButton}
        title={t('maptv:SummaryScene.SocialBanner.shareFacebook')}
        onClick={() =>
          onClickSocial(
            'facebook',
            `https://www.facebook.com/sharer.php?u=${website}`,
          )
        }
      >
        <FacebookIcon />
      </button>

      <button
        aria-label={t('maptv:SummaryScene.SocialBanner.shareEmail')}
        className={styles.printButton}
        title={t('maptv:SummaryScene.SocialBanner.shareEmail')}
        onClick={() =>
          onClickSocial(
            'email',
            `mailto:?subject=${encodeURIComponent(
              emailSubject || "I'm voting! Will you join me?",
            )}&body=${encodeURIComponent(shareText)}`,
          )
        }
      >
        <EmailIcon />
      </button>
    </>
  )
}

export default SocialButtons
